/*
 * @Author: 朱占伟
 * @LastEditors: 李云涛
 * @description: page description
 * @Date: 2021-09-09 13:34:15
 * @LastEditTime: 2022-04-11 16:08:02
 */
import axios from "axios";
import { message } from "antd";
import { AESAUTH, encrypt, decrypt} from "./aes";
axios.defaults.withCredentials = true;
import store from "../store";
// 基础地址
let baseURL = '/api';
const isDev = process.env.common.NODE_ENV === "development"
console.log(process.env.common, process.env.NODE_ENV,'==============process.evn')
if (process.env.NODE_ENV === 'production') {
  baseURL = process.env.common['API']+ baseURL;
  // console.log(baseURL,'===========测试地址路由路径')
}

const service=axios.create({
  baseURL,
  withCredentials:true,
  timeout: 10000
})

// console.log(baseURL,'===========路由路径')
// 请求拦截器
service.interceptors.request.use(config=>{
  console.log(config.url,'+++++++++++++++++++++++++');
  if(config.url !== '/auth/login'){
      console.log(localStorage.getItem('persist:manager'))
    if(!(localStorage.getItem('persist:manager'))){
      location.reload()
      localStorage.removeItem('persist:manager')
    }
    config.headers['X-TOKEN'] = store.getState().userInfo.token;
  }
  // console.log(JSON.parse(JSON.parse(localStorage.getItem('persist:manager')).userInfo).token);
// && process.env.NODE_ENV === 'production'
  if(AESAUTH[config.url] && process.env.NODE_ENV === 'production' && !isDev ){
  // if(AESAUTH[config.url]){
    config.headers['Content-Type']='text/plain';
    config.data = encrypt(config.data)
  } else {
    config.headers['Content-Type']='application/json';
  }

  return config;
},error=>{
  console.log(error);
  return Promise.reject(error);
});

// 响应拦截器
service.interceptors.response.use(response=>{
   //响应正确
   if(response.data.code === 401 || response.data.code === 20002 || response.data.code === 10003 || response.data.code === 20003){
     message.error('账号被禁用')
     localStorage.removeItem('persist:manager')
     location.reload()
   }
   if(response.status>=200 && response.status<=210){
     const data=response.data
     return data
   }
   return response.data;
},error=>{
    const status = error.response && error.response.status || 'error';
    if (status === 401) {
        message.error(error)
        localStorage.removeItem('persist:manager')
        window.location = '/login'
        return Promise.resolve(error.response.data || '系统异常');
    } else {
        console.log(error,'error')
        return Promise.resolve(error.response && error.response.data || (error || '系统异常'));
    }
  // return Promise.resolve(error.response);
})


/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function get(url,params={}){
    try{
     return service({
       url,
       params,
       method:'GET',
     })
    }catch(error){
      console.error('get:', error);
    }
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
 export function post(url, data={}){
  try {
    return service({
      url,
      data,
      method: 'POST',
    });
  } catch (error) {
    console.error('post:', error);
  }
}
