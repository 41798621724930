/*
 * @Author: 朱占伟
 * @LastEditors: 李云涛
 * @description: 手机号认证
 * @Date: 2022-03-24 11:24:07
 * @LastEditTime: 2022-04-11 16:44:28
 */


import { Form, Input, Button, Checkbox, message } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom"
import { minPHONE, CODE } from "src/config/regexp.config"

import { getAuthCode, checkAuthCode } from '../../model/auth';

import { setPhoneAuth, setIdentity } from '../../store/action/user';


//已校验展示
const confirmRes = (phone) => {
  return (
    <div className="auth-phone-res">
      <span>已认证手机码</span>
      <span className='text-l ml-20 font-main font-weight'>{phone || null}</span>
    </div>
  )
}


export default function AuthPhone(props) {

  const userInfo = useSelector(state => state.userInfo)

  const dispatch = useDispatch()

  const setPhoneVal = (e)=>{dispatch(setPhoneAuth(e))}

  const [disSend, setDisSend] = useState(0)

  function intervalSendTime() {
    setDisSend(60)
  }

  useEffect(()=>{
    if(disSend !== 0){
      const times = setTimeout(()=>{
        setDisSend(disSend-1)
      }, 1000)
    
      if(disSend === 0){
        clearInterval(times)
      }
    }
  },[disSend])

  const getUserAuthCode = async () => {
    if(phone.remember){
      const res = await getAuthCode()
      if(res && res.code && res.data){
        message.success('发送成功')
      }else{
        message.error(res.message)
      }
      intervalSendTime()
    }else{
      message.error('请先同意用户协议')
      return
    }
  }

  const checkUserAuthCode = async () => {
    const res = await checkAuthCode({num: phone.checkCode})
    if(res && res.code === 200 && res.data){
      setPhone({
        ...phone,
        codeRight: true
      })
      return true
    }else{

      message.error(res.message)

      return false
    }
  }

  //校验数据
  const [phone, setPhone] = React.useState({
    code: '',  //手机号
    checkCode: '',//验证码
    codeRight: false, //验证码是否正确
    remember: false
  })


  //修改已有的值
  const updatePhone = (val, type = 'code') => {
    const obj = Object.assign(phone, {
      [type]: val
    })
    setPhone(obj)
  }

  //校验传参
  const checkParams = (obj) => {
    console.log("校验提交数据", obj)
    const res = {
      isPass: true,
      msg: ''
    }

    if (!obj.code) {
      res.isPass = false
      res.msg = '请输入手机号码'
      return res
    }

    if (!minPHONE.test(parseInt(obj.code))) {
      res.isPass = false
      res.msg = '请输入正确格式的手机号码'
      return res
    }

    if (!obj.checkCode) {
      res.isPass = false
      res.msg = '请输入验证码'
      return res
    }

    if (!CODE.test(obj.checkCode)) {
      res.isPass = false
      res.msg = '请输入正确格式的验证码'
      return res
    }

    if (!obj.remember) {
      res.isPass = false
      res.msg = '请先阅读并确认用户协议'
      return res
    }
    return res
  }


  //点击下一步
  const onFinish = async() => {

    //如果验证跳过校验
    if(!userInfo.phoneNumAuth){

      //校验数据合法性

      const checkRes = checkParams(phone)

      if (!checkRes.isPass) {
        return message.error(checkRes.msg)
      }


      let res = await checkUserAuthCode()


      if(!res){
        return
      }

      setPhoneVal(true)

    }

    //校验成功
    props.next()
  };

  //判定验证号码以及是否验证
  const authPhone = () => {
    if(userInfo){
      if(userInfo.phoneNumAuth){
        setPhone({
          ...phone,
          code: userInfo.phone,
          codeRight: true
        })
      }
      setPhone({
        ...phone,
        code: userInfo.phone
      })
    }
  }

  useEffect(() => {
    authPhone()  //判定验证号码以及是否验证
    return () => {};
  }, []);


  return (
    <div className='auth-phone pb-40'>
      <p>为保障您的账户安全，请进行信息认证</p>
      <p>平台授权支付宝安全认证，平台不会泄露您的认证信息</p>
      {
        userInfo && !userInfo.phoneNumAuth && <Form
          name="checkPhone"
          initialValues={{ remember: phone.remember }}
          autoComplete="off"
        >
          <Input disabled placeholder='输入手机号' value={phone.code} maxLength="11" type="text" className='mt-20' onBlur={(ev) => updatePhone(ev.target.value)} />

          <div className='flex mt-20'>
            <Input placeholder='输入验证码' type="text" maxLength="6" onBlur={(ev) => updatePhone(ev.target.value, 'checkCode')} /><Button disabled={ disSend !== 0 } type="primary" onClick={getUserAuthCode}>{disSend!==0 ? `${disSend}S`: '获取验证码'}</Button>
          </div>

          <Form.Item name="remember" valuePropName="checked" className='mt-20'>
            <Checkbox onChange={(ev) => updatePhone(!phone.remember, 'remember')}>
              我已阅读并同意 <Link to="/protocol" > 《HTTP协议》</Link>

            </Checkbox>
          </Form.Item>
        </Form> || confirmRes(phone.code)
      }

      <div>
        <Button type="primary" onClick={onFinish}>
          下一步
        </Button>
      </div>
    </div>
  )

}