/*
 * @Author: 李云涛
 * @LastEditors: 李云涛
 * @description: page description
 * @Date: 2022-03-23 10:22:13
 * @LastEditTime: 2022-04-07 12:56:54
 */
import './index.less'

import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useBind } from 'utils/useBind';

import { Form, Input, Button, Table, Modal, Switch, message, ConfigProvider } from "antd"
import zhCN from "antd/es/locale/zh_CN"

import { addAuthResult, getAuthResult, editAuthResult, delAuthResult, replaceIp } from "../../model/whiteList"

//导入生成列表列函数 -- 多选配饰
import { generateColumns, rowSelection } from "./data"

function IpWhiteList() {

  //redux
  const userInfo = useSelector(state => state.userInfo)

  const navigate = useNavigate()

  const formRef = useRef(null)

  //表格数据
  const [data, setData] = useState({})

  //多选数据
  const [selection, setSelection] = useState([])

  function onSelectChange(val) {
    setSelection(val)
  }

  //备注修改信息
  const inputIp = useBind('')
  const inputDesc = useBind('')

  //修改备注弹窗
  const [ipModalVisible, setIpModalVisible] = useState(false);
  const [ip, setIp] = useState(null)
  const [total, setTotal] = useState(null)

  function showIpModal(e, ips = null, total = null) {
    console.log(ip, ips);
    if (ips) {
      setIp(ips);
      setTotal(total)
    }
    setIpModalVisible(true)
  }

  //增加白名单
  async function addDesc(clientIP, desc, userId) {
    const res = await addAuthResult({
      clientIP,
      desc,
      userId,
    })
    if(res && res.code === 200){
      message.success('增加成功')
      init()
    }else{
      message.error(res.message)
    }
  }

  //修改备注
  async function editDesc(id, desc) {
    const res = await editAuthResult({
      id,
      desc
    })
    if(res && res.code === 200){
      message.success('修改成功')
      init()
    }else{
      message.error(res.message)
    }   
  }

  function handleIpOk() {
    console.log(formRef);
    formRef.current.validateFields()
      .then((values) => {
        if (ip) {
          editDesc(ip, values.desc)
        }else{
          addDesc(values.ip,values.desc, userInfo.id)
        }
        setIpModalVisible(false);
        setIp(null)
        setTotal(null)
      })
      .catch(() => {
        return false
      })
  };

  function handleIpCancel() {
    setIpModalVisible(false);
    setIp(null)
    setTotal(null)
  };

  //移除弹窗
  const [removeModalVisible, setRemoveModalVisible] = useState(false)
  const batchRemove = useRef(false)

  function showRemoveModal(e, val) {
    //判断是否为单独移除，是val为id
    if (!val) {
      if (selection.length === 0) {
        message.error('请选择节点')
        return
      }
      batchRemove.current = true
    } else {
      setIp(val)
    }
    setRemoveModalVisible(true);
  };

  function handleRemoveOk() {
    console.log(batchRemove.current);
    if (batchRemove.current) {
      console.log(selection);
      removeList(selection.toString())
    } else {
      removeList(ip)
    }
    setIp(null)
    batchRemove.current = false
    setRemoveModalVisible(false);
  };

  function handleRemoveCancel() {
    batchRemove.current = false
    setRemoveModalVisible(false);
  };

  //移除函数
  async function removeList(val) {
    let params = {
      id: val,
      userId: userInfo.id
    }
    const res = await delAuthResult(params)
    if(res && res.code === 200){
      message.success('删除成功')
      setSelection([])
      init()
    }else{
      message.error(res.message)
    }
  }

  //跳转提取ip
  function goWhiteList(val) {
    navigate('/extractIp',{
      state: {
        whiteList: {
          height: val
        }
      }
    })
  }

  //获取数据
  async function init() {
    const res = await getAuthResult({
      userId: userInfo.id
    })
    if(res && res.code === 200){
      setSwitchVal(res.data.wIpReplace ? true : false)
      setData(res.data)
      paginationProps.current = {
        showQuickJumper: true,
        showSizeChanger: true,
        // onChange: (page,size) => { changePage(page,size) }
      }
    }else{
      message.error(res.message)
    }
  }

  //自动替换开关值
  const [switchVal, setSwitchVal] = useState(false)

  //切换自动替换
  async function changeSwitch(e, val) {
    let re = {
      userId: userInfo.id,
      replace: e
    }
    const res = await replaceIp(re)
    if(res && res.code === 200){
      if(!switchVal){
        message.success('已开启自动替换！')
      }else if(switchVal){
        message.success('已关闭自动替换！')
      }
      setSwitchVal(!switchVal)
    }else{
      message.error(res.message)
    }
  }

  //表格分页配置
  const paginationProps = useRef({
    showQuickJumper: true,
    showSizeChanger: true,

    // onChange: (page,size) => { changePage(page,size) }
  })

  useEffect(()=>{
    init()
    return ()=>{
      init = null
    }
  },[])


  return (
    <React.Fragment>
      <div className="whitelist-form-wrap">
        <div className="whitelist-top-form">
          <div className='mb-10'>
            <span className='mr-20'>已设/总IP白名单数：{ data.total }/{ data.sum }</span>
            <span className='mr-50 red-text'>使用IP需添加白名单</span>
          </div>
          <div className='flex justify-between align-center'>
            <div>
              <span className='mr-10'>提取IP自动替换</span>
              <Switch
                checked={switchVal}
                onChange={changeSwitch}
              />
              <Button type="link" className='ml-30' onClick={()=>{goWhiteList(50)}}>点击获取白名单添加接口</Button>
              <Button type="link" onClick={()=>{goWhiteList(35)}}>点击获取白名单删除接口</Button>
              <Button type="link" onClick={()=>{goWhiteList(10)}}>点击获取白名单查询接口</Button>
            </div>
            <div>
              <Button type='primary' onClick={showIpModal}> 添加白名单 </Button>
              <Button className='ml-20 select-button' onClick={(e) => { showRemoveModal(e, false) }}> 批量移除 </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="whitelist-table-wrap">
        <ConfigProvider locale={zhCN}>
          <Table
            rowKey='id'
            rowSelection={rowSelection({ selection, onSelectChange })}
            columns={generateColumns({ showIpModal, showRemoveModal })}  //使用函数生成表格列
            dataSource={data.list}  //数据源
            pagination={paginationProps.current}
          />
        </ConfigProvider>
      </div>
      <Modal
        destroyOnClose
        title="IP白名单"
        visible={ipModalVisible}
        onOk={handleIpOk}
        onCancel={handleIpCancel}
        cancelText="取消"
        okText="确定"
        width={400}
      >
        <Form
          labelCol={{ span: 6 }}
          ref={formRef}
          preserve={false}
          initialValues={{
            desc: total
          }}
        >
          <Form.Item
            style={{ display: ip ? 'none' : 'flex' }}
            label="选择地区"
            name="ip"
            rules={[{ required: !ip, message: '请输入IP地址!', pattern: /^((2(5[0-5]|[0-4]\d)|([1]\d{1,2})|([0-9]\d)|[1-9])(\.((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})){3}$)/ }]}
          >
            <Input placeholder='请输入IP地址' autoComplete='off' />
          </Form.Item>
          <Form.Item
            label="备注信息"
            name="desc"
          >
            <Input.TextArea name='desc' placeholder="请输入备注信息" />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        destroyOnClose
        title="提示"
        visible={removeModalVisible}
        onOk={handleRemoveOk}
        onCancel={handleRemoveCancel}
        cancelText="取消"
        okText="确定"
        width={400}
      >
        确认删除？
      </Modal>
    </React.Fragment>
  )
}

export default IpWhiteList