/*
 * @Author: 李云涛
 * @LastEditors: 李云涛
 * @description: page description
 * @Date: 2022-03-24 16:13:52
 * @LastEditTime: 2022-03-24 18:35:00
 */

//登录方法
async function userLogin (params = null) {
  try {
    const res = await React.$post(React.$api.AUTH_LOGIN, params)
    return res
  } catch (error) {
    console.error('test:', error)
    return Promise.resolve(null)
  }
} 

//登出方法
async function userLogout (params = null) {
  try {
    const res = await React.$post(React.$api.AUTH_LOGINOUT, params)
    return res
  } catch (error) {
    console.error('test:', error)
    return Promise.resolve(null)
  }
} 

export {
  userLogin,
  userLogout,
}