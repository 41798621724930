/*
 * @Author: 李云涛
 * @LastEditors: 李云涛
 * @description: page description
 * @Date: 2022-01-05 09:54:27
 * @LastEditTime: 2022-02-22 13:47:15
 */

import { useState } from "react";

function useBind(val) {
  const [value, setValue] = useState(val||'')

  const onChange = e => {
    setValue(e ? e.target.value : '')
  }

  return {
    value,
    onChange,
  }
}

export { useBind }