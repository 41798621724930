/*
 * @Author: 李云涛
 * @LastEditors: 李云涛
 * @description: page description
 * @Date: 2022-03-31 14:10:42
 * @LastEditTime: 2022-03-31 14:12:33
 */

//获取白名单数据
async function getPackageData (params = null) {
  try {
    const res = await React.$post(React.$api.MEAL_PROXY_LIST, params)
    return res
  } catch (error) {
    console.error('test:', error)
    return Promise.resolve(null)
  }
} 

export {
  getPackageData
}