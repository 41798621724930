/*
 * @Author: 李云涛
 * @LastEditors: 李云涛
 * @description: 用户方法
 * @Date: 2022-03-31 10:17:21
 * @LastEditTime: 2022-03-31 10:21:08
 */

//修改密码
async function updatePwd (params = null) {
  try {
    const res = await React.$post(React.$api.USER_UPDATE_PASSWORD, params)
    return res
  } catch (error) {
    console.error('test:', error)
    return Promise.resolve(null)
  }
} 

//修改手机号
async function updatePhone (params = null) {
  try {
    const res = await React.$post(React.$api.USER_UPDATE_PHONE, params)
    return res
  } catch (error) {
    console.error('test:', error)
    return Promise.resolve(null)
  }
} 

//修改自动替换
async function updateReplace (params = null) {
  try {
    const res = await React.$post(React.$api.USER_UPDATE_AUTOREPLACE, params)
    return res
  } catch (error) {
    console.error('test:', error)
    return Promise.resolve(null)
  }
} 

export {
  updatePwd,
  updatePhone,
  updateReplace,
}