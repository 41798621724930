/*
 * @Author: 李云涛
 * @LastEditors: 李云涛
 * @description: page description
 * @Date: 2022-03-24 15:53:52
 * @LastEditTime: 2022-04-11 16:48:30
 */
import { useRoutes, Navigate } from "react-router-dom";
 
import Login from "views/login/index"
import Auth from "views/auth/index"
import Protocol from "src/components/protocol";

export default function Routes() {

  let element = useRoutes([
    { path: "/login", element: <Login /> },
    { path: "/auth", element: <Auth /> },
    { path: "/protocol", element: <Protocol /> },
    { path: "*", element: <Navigate to="/login" /> },
  ]);

  return element;
  
}