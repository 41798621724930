/*
 * @Author: 朱占伟
 * @LastEditors: 李云涛
 * @description: 操作state
 * @Date: 2021-10-18 14:44:58
 * @LastEditTime: 2022-04-08 10:23:40
 */


const initalState = {
  userInfo: null, //当前用户登录信息
  count: [1],
}

const reducer = (state = initalState, action) => {
  switch (action.type) {
    
    //用户登录信息
    case 'LOGIN':
      console.log("reducer 设置是否登录", action)
      return {
        ...state,
        userInfo: action.value || null
      }


    //设置电话认证
    case 'SET_PHONE': 
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          phoneNumAuth: action.value || false
        }
      }


    //设置身份认证
    case 'SET_INDETITY': 
    return {
      ...state,
      userInfo: {
        ...state.userInfo,
        identityAuth: action.value || false
      }
    }

    //修改密码或者手机号
    case 'SET_PHONEDATA': 
    return {
      ...state,
      userInfo: {
        ...state.userInfo,
        ...action.value
      }
    }

    //手动清空
    case 'LOGOUT':
    return {
      ...state,
      userInfo: action.value || null,
    }

    //重置
    case 'SETNAV':
    return {
      ...state,
      count: action.value || 1,
    }


    default:
      return state
  }
}
export default reducer