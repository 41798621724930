/*
 * @Author: 朱占伟
 * @LastEditors: 李云涛
 * @description: 公共头部
 * @Date: 2021-10-18 16:00:42
 * @LastEditTime: 2022-03-31 17:19:12
 */
import "./index.less"

import React, { useEffect, useRef, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { useSelector } from "react-redux"

import { Menu } from "antd"

import SelectAction from "components/SelectAction"

export default function Header () {

  //redux
  const userInfo = useSelector(state => state.userInfo)

  const navigate = useNavigate()
  const location = useLocation()

  const navData = useRef([
    {
      key: '1',
      title:" 套餐管理",
      path: '/'
    },
    {
      key: '2',
      title:" 提取IP",
      path: '/extractIp'
    },
    {
      key: '3',
      title:" IP白名单",
      path: '/ipWhitelist'
    },
  ])

  function handleClick (e, re='/') {
    if(location.path !== re){
      navigate(re)
    }
  }

  const [key, setKey] = useState([])

  useEffect(()=>{
    for(let i of navData.current){
      if(i.path === location.pathname){
        setKey([i.key])
      }
    }
  },[location.pathname])

  return (
      <React.Fragment>
        <div className='flex'>
          {/* logo */}
          <section className="logo">
            <h1>客户后台</h1>
          </section>
          {/* 导航 */}
          <Menu
              // defaultSelectedKeys={key}
              selectedKeys={key}
              mode="horizontal"
              theme="light"
              className='ml-70'
          >
            {
              navData.current.map((item)=>{
                return (
                    <div className='ml-20 cursor' key={item.key} onClick={(e)=>{handleClick(e, item.path)}} >
                      {item.title}
                    </div>
                )
              })
            }
          </Menu>
        </div>
        {/* 登录人 */}
        <section className='user'>
          <div className="user-name text-center">
            <span>{ userInfo.username }</span>
            <SelectAction />
          </div>
        </section>

      </React.Fragment>
  )
}
