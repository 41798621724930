/*
 * @Author: 朱占伟
 * @LastEditors: 李云涛
 * @description: page description
 * @Date: 2021-09-10 11:27:29
 * @LastEditTime: 2022-04-08 16:36:26
 */
import "./index.less"
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { message, Select, Radio, Button, Input, InputNumber, Table, Checkbox, ConfigProvider } from "antd";
import zhCN from "antd/es/locale/zh_CN"

import { getCity, getPackage } from "src/model/extractIp";
import { getWhiteListUrl } from "src/model/whiteList";

import {
  dataFormat,
  delimiter,
  areaSelect,
  operator,
  ipProtocol,
  illustrateConfig,
  textExampleConfig,
  jsonExampleConfig,
  paramsConfig,
  extractIpIllustrate,
  selectProps
} from "./data.js"

function ExtractIp() {

  //redux
  const userInfo = useSelector(state=>state.userInfo)

  const location = useLocation()

  const whiteListRef = useRef(null)

  function to(toEl, e) {
    // toEl 为指定跳转到该位置的DOM节点    
    let bridge = toEl.current;
    let body = document.body;
    let height = 0;
    // 计算该 DOM 节点到 body 顶部距离    
    do {
      height += bridge.offsetTop;
      bridge = bridge.offsetParent;
    } while (bridge !== body)
    // 滚动到指定位置    
    window.scrollTo({
      top: height - e,
      behavior: 'smooth'
    })
  }

  //套餐选择选项
  const [selectOptions, setSelectOptions] = useState([])

  //套餐选择
  const [combo, setCombo] = useState(null)

  function changeCombo(e) {
    setCombo(e)
  }

  //套餐选择次数
  const [comboNum, setComboNum] = useState(1)

  function changeComboNum(e) {
    setComboNum(e)
  }

  //单选组
  const [radioGroup, setRadioGroup] = useState({
    dataFormat: 'txt',
    delimiter: 1,
    selectProps: 1000,
    areaSelect: 1,
    areaSelectPlace: null,
    operator: 1,
    ipProtocol: 0
  })

  //改变单选组
  function changeRadioGroup(e, key) {
    let obj = {}

    if(key === 'selectProps'){
      let count = 1000

      for(let i of e){
        count += i
      }

      obj[key] = count

    } else if( key === 'areaSelectPlace' ){

      obj[key] = e ? e.toString() : null

    } else {

      obj[key] = e.target.value

    }
    setRadioGroup({
      ...radioGroup,
      ...obj
    })
    obj = null
  }


  //获取API
  function generateApi() {
    const { brand } = userInfo
    let res = null
    if(brand === 1){
      res = spellSlUrl('http://api.shenlongip.com/ip')
    }else if(brand === 2){
      res = spellTqUrl('http://api.tianqiip.com/getip')
    }
    setTextareaData(res)
  }

  //神龙拼接字符串逻辑
  function spellSlUrl(url) {
    const { dataFormat,
      delimiter,
      selectProps,
      areaSelect,
      areaSelectPlace,
      operator,
      ipProtocol 
    } = radioGroup
    
    if(!combo){
      message.error('请选择套餐')
      return 
    }

    let apiUrl = url+'?'+`key=${combo}&count=${comboNum}&pattern=${dataFormat}&protocol=${ipProtocol}`

    if(dataFormat === 'txt'){
      if(delimiter !== 1){
        apiUrl += `split=${delimiter}`
      }
    }else if(dataFormat === 'json'){
      if(selectProps === 1000){
        message.error('请选择属性')
        return 
      }
      apiUrl += `&need=${selectProps}`
    }

    if(areaSelect === 2){
      if(!areaSelectPlace){
        message.error('请选择省份')
        return 
      }
      apiUrl += `&area=${areaSelectPlace.toString()}`
    }

    if(operator !== 1){
      apiUrl += `&isp=${operator}`
    }

    return apiUrl

  }

  //天启拼接字符串逻辑
  function spellTqUrl(url) {
    const { dataFormat,
      delimiter,
      selectProps,
      areaSelect,
      areaSelectPlace,
      operator,
      ipProtocol 
    } = radioGroup
    
    if(!combo){
      message.error('请选择套餐')
      return 
    }

    let apiUrl = url+'?'+`secret=${combo}&num=${comboNum}&type=${dataFormat}&port=${ipProtocol}`

    if(dataFormat === 'txt'){
      if(delimiter !== 1){
        apiUrl += `lb=${delimiter}`
      }
    }else if(dataFormat === 'json'){
      if(selectProps === 1000){
        message.error('请选择属性')
        return 
      }
      apiUrl += `&need=${selectProps}`
    }

    if(areaSelect === 2){
      if(!areaSelectPlace){
        message.error('请选择省份')
        return 
      }
      apiUrl += `&region=${areaSelectPlace.toString()}`
    }

    if(operator !== 1){
      apiUrl += `&yys=${operator}`
    }

    return apiUrl

  }

  //API文本框
  const [textareaData, setTextareaData] = useState('')
  const textareaRef = useRef(null)

  //复制
  function copyTextarea() {
    console.log(textareaRef.current.resizableTextArea.textArea.select);
    textareaRef.current.resizableTextArea.textArea.select()
    window.document.execCommand('copy')
  }

  //打开链接
  function openApi() {
    if (!textareaData) {
      message.error({
        content: '请先生成API链接',
        duration: 2,
        style: {
          color: 'red'
        }
      })
      return
    }
    window.open(textareaData, "_blank")
  }

  //获取套餐下拉
  async function getPackageSelect(e) {
    const { state } = location
    const res = await getPackage(e)
    if(res && res.code === 200){
      console.log(res, 'selrctOptions');
      setSelectOptions(res.data)
      if(state && state.package){
        for(let i of res.data){
          if(i.name === state.package){
            setCombo(i.key)
          }
        }
      }
    }else{
      message.error(res.message)
    }
  }

  function toHerf() {
    const { state } = location
    let params = {
      brand: userInfo.brand,
      mealName: state ? state.package || null : null,
      userId: userInfo.id
    }


    getPackageSelect(params)


    if (
      state &&
      state.whiteList &&
      state.whiteList.height
    ) {
      to(whiteListRef, state.whiteList.height)
    }
    console.log(location);
  }



  //api文档  
  const [apiT, setApiT] = useState({})


  //获取url + 渲染表格
  async function getWhiteUrl() {
    const res = await getWhiteListUrl({
      userId: userInfo.id
    })
    console.log(res.data);
    if(res && res.code === 200){
      setApiT(extractIpIllustrate(userInfo.brand, res.data))
    }else{
      setApiT(extractIpIllustrate(userInfo.brand))
    }
    setTimeout(()=>{
      toHerf()
    },0)
  }

  useEffect(()=>{

    getWhiteUrl()

  },[])

  const [place, setPlace] = useState([])

  //获取城市
  async function getCityCheckbox() {
    const res = await getCity()
    

    if(res && res.code === 200){
      setPlace(res.data)
    }
  }

  useEffect(()=>{
    getCityCheckbox()
  }, [])



  return (
    <React.Fragment>
      <div className="mt-30 ml-30 mr-30 extract-ip-wrap">
        <h1 className="ml-30 pt-10">提取IP</h1>
        <hr />
        <div className="ml-30 mr-30">
          <React.Fragment>
            <div className="title">
              套餐选择
            </div>
            <div>
              <ConfigProvider locale={zhCN}>
                <Select className="select mr-30" placeholder="请选择套餐" onChange={changeCombo} value={combo}>
                  {
                    selectOptions.map((item) => {
                      return (
                        <Select.Option key={item.id} value={item.key}>{item.sequence}</Select.Option>
                      )
                    })
                  }
                </Select>
              </ConfigProvider>
              <InputNumber min={1} defaultValue={1} onChange={changeComboNum} />
            </div>
          </React.Fragment>
          <React.Fragment>
            <div className="title">
              数据格式
            </div>
            <div>
              <Radio.Group
                options={dataFormat}
                value={radioGroup.dataFormat}
                optionType="button"
                buttonStyle="solid"
                onChange={(e) => { changeRadioGroup(e, 'dataFormat') }}
              />
            </div>
          </React.Fragment>
          <React.Fragment>
            <div style={{display: radioGroup.dataFormat === 'txt' ? 'block' : 'none'}}>
              <div className="title">
                分隔符
              </div>
              <div>
                <Radio.Group
                  options={delimiter}
                  value={radioGroup.delimiter}
                  optionType="button"
                  buttonStyle="solid"
                  onChange={(e) => { changeRadioGroup(e, 'delimiter') }}
                />
              </div>
            </div>
            <div style={{display: radioGroup.dataFormat === 'json' ? 'block' : 'none'}}>
              <div className="title">
                选择属性
              </div>
              <div>
                <Checkbox.Group
                  options={selectProps}
                  onChange={(e) => { changeRadioGroup(e, 'selectProps') }}
                />
              </div>
            </div>
          </React.Fragment>
          <React.Fragment>
            <div className="title">
              地区选择
            </div>
            <div>
              <Radio.Group
                options={areaSelect}
                value={radioGroup.areaSelect}
                optionType="button"
                buttonStyle="solid"
                onChange={(e) => { changeRadioGroup(e, 'areaSelect') }}
              />
            </div>
            <div className="mt-10" style={{display: radioGroup.areaSelect === 2 ? 'block' : 'none'}}>
              <Checkbox.Group
                options={place}
                onChange={(e) => { changeRadioGroup(e, 'areaSelectPlace') }}
              />
            </div>
          </React.Fragment>
          <React.Fragment>
            <div className="title">
              运营商
            </div>
            <div>
              <Radio.Group
                options={operator}
                value={radioGroup.operator}
                optionType="button"
                buttonStyle="solid"
                onChange={(e) => { changeRadioGroup(e, 'operator') }}
              />
            </div>
          </React.Fragment>
          <React.Fragment>
            <div className="title">
              IP协议
            </div>
            <div className="mb-30">
              <Radio.Group
                options={ipProtocol}
                value={radioGroup.ipProtocol}
                optionType="default"
                buttonStyle="solid"
                onChange={(e) => { changeRadioGroup(e, 'ipProtocol') }}
              />
            </div>
          </React.Fragment>
          <React.Fragment>
            <Button onClick={generateApi} type="primary" className="mr-20 select-button"> 生成API链接</Button>
            <Button className="mr-20" type="primary" onClick={copyTextarea}> 复制链接</Button>
            <Button onClick={openApi}> 打开链接</Button>
            <div className="mt-30 mb-30">
              <Input.TextArea ref={textareaRef} value={textareaData} placeholder="请生成API链接" autoSize={{ minRows: 3 }} />
            </div>
          </React.Fragment>
        </div>
        <div className="use-wrap ml-30 mr-30">
          <div className="use-title mb-5">
            使用说明
          </div>
          <ul className="use-text">
            <li>* 生成API链接，调用GET请求即可返回所需的IP结果</li>
            <li>* 您的所有访问操作均有日志保存，望遵纪守法</li>
            <li>* 请使用合理的频率去调用API接口，暴力调用将导致您的客户端IP被防火墙封禁</li>
          </ul>
        </div>
        <div className="ml-30 mr-30">
          <React.Fragment>
            <div className="ml-30 mt-30 mb-15 table-title">
              使用说明
            </div>
            <Table columns={illustrateConfig} dataSource={apiT.illustrate} pagination={false} rowKey={(record)=>record.attributes} />
          </React.Fragment>
          <React.Fragment>
            <div className="ml-30 mt-30 mb-15 table-title">
              返回结果示例
            </div>
            <Table columns={textExampleConfig} dataSource={apiT.textExample} pagination={false} rowKey={(record)=>record.format}/>
            <Table columns={jsonExampleConfig} dataSource={apiT.jsonExample} pagination={false} rowKey={(record)=>record.format}/>
          </React.Fragment>
          <React.Fragment>
            <div className="ml-30 mt-30 mb-15 table-title" ref={whiteListRef}>
              白名单接口
            </div>
            <Table columns={illustrateConfig} dataSource={apiT.whitelistIllustrate} pagination={false} rowKey={(record)=>record.attributes} />
          </React.Fragment>
          <React.Fragment>
            <div className="ml-30 mt-30 mb-15 table-title">
              通用
            </div>
            <Table columns={paramsConfig} dataSource={apiT.universalParams} pagination={false} rowKey={(record)=>record.params} />
          </React.Fragment>
          <React.Fragment>
            <div className="ml-30 mt-30 mb-15 table-title">
              直连IP
            </div>
            <Table columns={paramsConfig} dataSource={apiT.connectParams} pagination={false} rowKey={(record)=>record.params} />
          </React.Fragment>
          <React.Fragment>
            <div className="ml-30 mt-30 mb-15 table-title">
              转发
            </div>
            <Table columns={paramsConfig} dataSource={apiT.forwardParams} pagination={false} rowKey={(record)=>record.params} />
          </React.Fragment>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ExtractIp