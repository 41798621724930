/*
 * @Author: 李云涛
 * @LastEditors: 李云涛
 * @description: page description
 * @Date: 2022-03-22 16:42:21
 * @LastEditTime: 2022-04-07 12:04:36
 */
import React from "react"
import { EditOutlined } from '@ant-design/icons';

import { Button } from "antd";
import formatTime from "../../utils/formatTime";

function generateColumns(func = {}) {
   //重置密码，提取ip
  const { showIpModal, showRemoveModal } = func

  return [
    {
      align: 'center',
      title: 'IP地址',
      dataIndex: 'clientIP',
      key: 'clientIP',
      width: 600
    },
    {
      align: 'center',
      title: '设置时间',
      dataIndex: 'createTime',
      key: 'createTime',
      width: 300,
      render: createTime => {
        return formatTime(createTime)
      }
    },
    {
      align: 'center',
      title: '备注',
      dataIndex: 'desc',
      key: 'desc',
      render: (text, record) => (
        <React.Fragment>
          <span>{record.desc || '--'}</span>
          <Button type="link" icon={<EditOutlined onClick={(e)=>{showIpModal(e, record.id, record.desc)}} />} />
        </React.Fragment>
      )
    },
    {
      align: 'center',
      title: '操作',
      key: 'operate',
      width: 100,
      render: (text, record) => {
        return (
          <Button type="link" onClick={(e)=>{showRemoveModal(e, record.id)}}>移除</Button>
        )
      }
    },
  ]
}

function rowSelection (options = {}) {
  const { selection, onSelectChange } = options
  return {
    selection,
    onChange: onSelectChange,
  }
}

export {
  generateColumns,
  rowSelection,
}