/*
 * @Author: 朱占伟
 * @LastEditors: 李云涛
 * @description: 用户相关action
 * @Date: 2021-10-18 14:53:54
 * @LastEditTime: 2022-04-02 10:06:22
 */


//设置是否认证
export const setAuth = (value = true) => ({
  type: 'AUTH',
  value
})


//设置登录信息
export const setLogin = (value = null) => ({
  type: 'LOGIN',
  value
})

//修改电话认证
export const setPhoneAuth = (value = false) => ({
  type: 'SET_PHONE',
  value
})

//修改身份认证
export const setIdentityAuth = (value = false) => ({
  type: 'SET_INDETITY',
  value
})

//修改个人信息
export const setPhone = (value = false) => ({
  type: 'SET_PHONEDATA',
  value
})

//手动清空用户信息
export const clearLogout = (value = null) => ({
  type: 'LOGOUT',
  value
})


//导航栏数据保存
export const changeNav = (value = null) => ({
  type: 'SETNAV',
  value
})