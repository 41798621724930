/*
 * @Author: 朱占伟
 * @LastEditors: 李云涛
 * @description: 应用容器
 * @Date: 2021-09-09 13:15:41
 * @LastEditTime: 2022-03-30 11:41:11
 */


import 'views/index';
import Router from 'src/routes/'

import { Layout } from "antd"

import Header from 'components/Header';

class App extends React.Component {
  constructor() {
    super();
  }

  state = {
    collapsed: false,
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };
  render() {
    return (
      <>
        <Layout className='container'>
          <Layout.Header className='header flex justify-between' >
            <Header />
          </Layout.Header>
          <Layout.Content className="site-layout">
            <Router />
          </Layout.Content>
        </Layout>
      </>
    )
  }

}

export default App