/*
 * @Author: 李云涛
 * @LastEditors: 李云涛
 * @description: page description
 * @Date: 2022-03-22 16:42:21
 * @LastEditTime: 2022-04-07 16:02:20
 */
import React from "react"

import { Button } from "antd";
import formatTime from "src/utils/formatTime";

function payTypeTime(e) {
  if(e.proxyType === 0){
    return (
      <React.Fragment>
        {formatTime(e.createTime) || '--'}<br />永久
      </React.Fragment>
    )
  }else{
    return (
      <React.Fragment>
        {formatTime(e.createTime) || '--'}<br />{formatTime(e.endTime) || '--'}
      </React.Fragment>
    )
  }
}

function generateColumns(func) {
  const { extractIp } = func //重置密码，提取ip
  return [
    {
      align: 'center',
      title: '套餐编号',
      dataIndex: 'sequence',
      key: 'sequence',
    },
    {
      align: 'center',
      title: '名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      align: 'center',
      title: '类型',
      dataIndex: 'proxyType',
      key: 'proxyType',
      render: proxyType => {
        switch (proxyType) {
          case 0: return (
            <span> 包量 </span>
          );
          case 1: return (
            <span> 包时 </span>
          );
          case 10: return (
            <span> 计次 </span>
          )
          case 60: return (
            <span> 福利 </span>
          )
          case 70: return (
            <span> 不限量 </span>
          )
          default: return <span> -- </span>
        }
      }
    },
    {
      align: 'center',
      title: '密钥',
      dataIndex: 'key',
      key: 'key',
    },
    {
      align: 'center',
      title: '账号',
      dataIndex: 'authKey',
      key: 'authKey',
    },
    {
      align: 'center',
      title: '密码',
      dataIndex: 'authSecret',
      key: 'authSecret',
      render: authSecret => {
        return (
          <React.Fragment>
            {authSecret}<br />
          </React.Fragment>
        )
      }
    },
    {
      align: 'center',
      title: '提取上限',
      dataIndex: 'total',
      key: 'total',
    },
    {
      align: 'center',
      title: '剩余|已提取',
      key: 'remainAndExtracted',
      render: (item, row, index) => {
        return (
          <React.Fragment>
            {row.total - row.used}|{row.used} 
          </React.Fragment>
        )
      }
    },
    {
      align: 'center',
      title: '起止时间',
      key: 'startAndEnd',
      render: (item, row, index) => {
        return payTypeTime(row)
      }
    },
    {
      align: 'center',
      title: '状态',
      key: 'state',
      dataIndex: 'state',
      render: state => {
        switch (state) {
          case 1: return (<span style={{color: '#4B7902'}}>启用</span>)
          case 2: return (<span style={{color: '#D9001B'}}>禁用</span>)
          case 3: return (<span style={{color: '#D9001B'}}>用完</span>)
          case 4: return (<span style={{color: '#D9001B'}}>到期</span>)
        }
      }
    },
    {
      align: 'center',
      title: '操作',
      key: 'operate',
      render: (item, row, index) => {
        return (
          <Button type="link" onClick={()=>{extractIp(row.state, row.name)}}>提取IP</Button>
        )
      }
    },
  ]
}

const packageStatus = [
  {
    id: 0,
    label: '全部'
  },{
    id: 1,
    label: '启用'
  },{
    id: 2,
    label: '禁用'
  },{
    id: 3,
    label: '用完'
  },{
    id: 4,
    label: '到期'
  },
]

export {
  generateColumns
}

export {
  packageStatus
}