/*
 * @Author: 朱占伟
 * @LastEditors: 李云涛
 * @description: 下拉选项框
 * @Date: 2022-03-21 14:45:04
 * @LastEditTime: 2022-04-11 09:44:20
 */
import "./index.less"

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Menu, Dropdown, Modal, Form, Input, Button, message } from "antd"
import { CaretDownOutlined, CaretUpOutlined, FormOutlined, EditOutlined, LogoutOutlined } from '@ant-design/icons';

import { minPHONE, CODE, CHANGE_PWD } from "src/config/regexp.config"
import { updatePwd, updatePhone } from "src/model/user";
import { getUpdatePhoneCode } from "src/model/auth";
import { userLogout } from "src/model/login";

import { clearLogout, setPhone } from "src/store/action/user";

const onFinish = (values) => {
  console.log('Success:', values);
};

const onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo);
};

//手机号校验
const checkUpdatePhoneParams = (obj) => {

  const res = {
    isPass: true,
    msg: false
  }

  if (!obj.originalPhone) {
    res.isPass = false
    res.msg = '请输入当前手机号'
    return res
  }
  if (!minPHONE.test(obj.originalPhone)) {
    res.isPass = false
    res.msg = '当前手机号输入格式错误'
    return res
  }

  if (!obj.newPhone) {
    res.isPass = false
    res.msg = '请输入新手机号'
    return res
  }

  if (!minPHONE.test(obj.newPhone)) {
    res.isPass = false
    res.msg = '新手机号输入格式错误'
    return res
  }
  if (obj.newPhone === obj.originalPhone) {
    res.isPass = false
    res.msg = '新设置的手机号不能是当前手机号'
    return res
  }
  if (!obj.code) {
    res.isPass = false
    res.msg = '请输入验证码'
    return res
  }

  if (!CODE.test(obj.code)) {
    res.isPass = false
    res.msg = '验证码只能是六位数字'
    return res
  }
  return res
}

//密码校验
const checkUpdatPwdParams = (obj) => {

  const res = {
    isPass: true,
    msg: false
  }

  if (!obj.originalPassword) {
    res.isPass = false
    res.msg = '请输入原密码'
    return res
  }

  if (!obj.newPassword) {
    res.isPass = false
    res.msg = '请输入新密码'
    return res
  }

  if (!CHANGE_PWD.test(obj.newPassword)) {
    res.isPass = false
    res.msg = '密码由6~20位字母/数字/特殊符号组成构成'
    return res
  }

  if (obj.newPassword === obj.originalPassword) {
    res.isPass = false
    res.msg = '新密码不能与原密码相同'
    return res
  }
  if (!obj.checkPassword) {
    res.isPass = false
    res.msg = '请重复新密码'
    return res
  }

  if (obj.newPassword !== obj.checkPassword) {
    res.isPass = false
    res.msg = '新密码与重复密码不一致'
    return res
  }
  return res
}

//修改密码方法
async function revisePwd(e) {
  // const res = await Promise.resolve({code: 200})
  return await updatePwd(e)
  // return res
}

//修改密码弹窗
const updatePwdConfirm = () => {
  const params = {
    originalPassword: '',
    newPassword: '',
    checkPassword: '',
  }

  Modal.confirm({
    confirmLoading: false,
    maskClosable: false,
    keyboard: false,
    destroyOnClose: false,
    closeIcon: false,
    okText: '保存',
    cancelText: '取消',
    title: '修改密码',
    content: (
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >

        <Form.Item
          label="原密码"
          name="originalPassword"
          rules={[{ required: true, message: '输入原密码!' }]}
        >
          <Input.Password autoComplete="off" placeholder="输入原密码" onBlur={(e) => { params.originalPassword = e.target.value }} />
        </Form.Item>

        <Form.Item
          label="新密码"
          name="newOld"
          rules={[{ required: true, message: '设置新密码!' }]}
        >
          <Input.Password autoComplete="off" placeholder="设置新密码" onBlur={(e) => { params.newPassword = e.target.value }} />
        </Form.Item>


        <Form.Item
          label="确认密码"
          name="checkPassword"
          rules={[{ required: true, message: '重复新密码!' }]}
        >
          <Input.Password autoComplete="off" placeholder="重复新密码" onBlur={(e) => { params.checkPassword = e.target.value }} />
        </Form.Item>

      </Form>
    ),
    onOk: (close) => {
      const checkRes = checkUpdatPwdParams(params)
      if (!checkRes.isPass) {
        message.error(checkRes.msg)
        return false
      }

      revisePwd(params)
        .then((res) => {
          if (res && res.code === 200) {
            message.success('修改成功')
            close()
          } else {
            message.error(res.message)
            close()
          }
        })
        .catch((error) => {
          message.error(error)
        })
    }
  });

}

//修改手机号方法
async function revisePhone(e) {
  return await updatePhone(e)
}



// 下拉功能选项
const funcs = [
  {
    name: '修改密码',
    key: 'pwd',
    icon: <FormOutlined />
  },
  {
    name: '修改手机号',
    key: 'phone',
    icon: <EditOutlined />
  },
  {
    name: '退出登录',
    key: 'logout',
    icon: <LogoutOutlined />
  }
]

export default function SelectAction() {

  const userInfo = useSelector(state => state.userInfo)

  //dispatch
  const dispatch = useDispatch()

  const clearUserInfoData = () => {
    setTimeout(() => {
      dispatch(clearLogout())
    }, 0)
  }

  const setPhoneData = (e) => {
    dispatch(setPhone({ phone: e }))
  }

  //退出登录logout
  const userLogoutFunc = async () => {
    const res = await userLogout()
    if (res && res.code === 200 && res.data) {
      clearUserInfoData()
    } else {
      message.error(res.message)
    }
    handleCancle()
  }



  //修改手机号弹窗隐藏
  const [phoneVis, setPhoneVis] = useState(false)

  const openPhoneVis = () => {
    setPhoneVis(true)
  }

  const closePhoneVis = () => {
    setPhoneVis(false)
    setPhoneParams({
      originalPhone: null,
      newPhone: null,
      code: null
    })
  }


  //验证码延迟
  const [disSend, setDisSend] = useState(0)

  function intervalSendTime() {
    console.log('set6+0');
    setDisSend(60)
  }

  useEffect(() => {
    if (disSend !== 0) {
      const times = setTimeout(() => {
        setDisSend(disSend - 1)
      }, 1000)

      if (disSend === 0 || !phoneVis) {
        clearInterval(times)
        setDisSend(0)
      }
    }
  }, [disSend, phoneVis])



  //获取验证码
  async function getUpdatePhoneCodeVal(e) {
    const { originalPhone, newPhone } = e
    if (!originalPhone) {
      return message.error('请输入原手机号')
    }
    if (!newPhone) {
      return message.error('请输入新手机号')
    }

    const res = await getUpdatePhoneCode({
      originalPhone,
      phone: newPhone
    })
    if (res && res.code === 200 && res.data) {
      message.success('发送成功')
    } else {
      message.error(res.message)
    }

    intervalSendTime()

  }

  const [phoneParams, setPhoneParams] = useState({
    originalPhone: null,
    newPhone: null,
    code: null
  })

  const changePhoneParams = (e, key, val) => {
    let re = Object.assign(phoneParams, {
      [key]: val
    })
    setPhoneParams(re)
  }


  //修改手机号码
  const updatePhoneConfirm = () => {
    console.log("保存")
    const checkRes = checkUpdatePhoneParams(phoneParams)
    if (!checkRes.isPass) {
      message.error(checkRes.msg)
      return false
    }

    revisePhone(phoneParams)
      .then((res) => {
        if (res && res.code === 200) {
          message.success('修改成功')
          setPhoneData(phoneParams.newPhone)
          closePhoneVis()
        } else {
          message.error(res.message)
          closePhoneVis()
        }
      })
      .catch((error) => {
        message.error(error)
      })
  }



  const [logoutShow, setLogoutShow] = useState(false)

  //修改退出弹出框
  const showLogout = () => {
    setLogoutShow(true)
  }

  const handleCancle = () => {
    setLogoutShow(false)
  }

  const [selected, setSelected] = useState(false)

  //触发下拉
  const downSelect = (e) => {
    setSelected(!selected)
  }


  //选中操作
  const changeEvent = ({ key }) => {
    if (key === 'pwd') {
      updatePwdConfirm()
    } else if (key === 'phone') {
      setPhoneParams({
        ...phoneParams,
        originalPhone: userInfo.phone
      })
      openPhoneVis()
    } else {
      showLogout()
    }
  }

  function menu(funcs) {
    return (<Menu>
      {
        funcs && funcs.map((item) => {
          return (
            <Menu.Item icon={item.icon} key={item.key} onClick={changeEvent}>
              {item.name}
            </Menu.Item>
          )
        })
      }
    </Menu>)
  }

  return (
    <React.Fragment>
      <Dropdown overlay={menu(funcs)} trigger="click" arrow={true} onVisibleChange={downSelect}>
        <a className="ant-dropdown-link">
          {
            !selected && <CaretDownOutlined style={{ color: 'black' }} className="ml-5 mr-15 v-middle" /> ||
            <CaretUpOutlined style={{ color: 'black' }} className="ml-5 mr-15 v-middle" />
          }
        </a>
      </Dropdown>
      <Modal
        title="退出登录"
        visible={logoutShow}
        onOk={userLogoutFunc}
        onCancel={handleCancle}
        okText="退出"
        cancelText="取消"
      >
        确认退出登录吗？
      </Modal>
      <Modal
        width={500}
        confirmLoading={false}
        maskClosable={false}
        keyboard={false}
        destroyOnClose={true}
        closeIcon={false}
        visible={phoneVis}
        onOk={updatePhoneConfirm}
        onCancel={closePhoneVis}
        okText='保存'
        cancelText='取消'
        title='修改手机号'
      >
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 12 }}
          initialValues={{ remember: true, oldPhone: userInfo.phone }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >

          <Form.Item
            label="当前手机号"
            name="oldPhone"
            rules={[{ required: true, message: '输入当前手机号!' }]}
          >
            <Input disabled autoComplete="off" placeholder="请输入当前手机号" />
          </Form.Item>

          <Form.Item
            label="新手机号"
            name="newPhone"
            rules={[{ required: true, message: '请输入新手机号!' }]}
          >
            <Input autoComplete="off" placeholder="请输入新手机号" onChange={(e) => {changePhoneParams(e, 'newPhone', e.target.value)}} />
          </Form.Item>


          <Form.Item
            label="验证码"
            name="idenfy"
            rules={[{ required: true, message: '验证码!' }]}

          >
            <div className="flex">
              <Input autoComplete="off" placeholder="输入验证码" style={{ width: '150px' }} onChange={(e) => {changePhoneParams(e, 'code', e.target.value)}} />
              <Button disabled={disSend !== 0} style={{ width: '80px' }} className="check-code" onClick={() => { getUpdatePhoneCodeVal(phoneParams) }} >{disSend !== 0 ? `${disSend}S` : '获取验证码'}</Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment >
  )
}