/*
 * @Author: 李云涛
 * @LastEditors: 李云涛
 * @description: 白名单接口方法
 * @Date: 2022-03-30 11:32:59
 * @LastEditTime: 2022-04-08 15:42:51
 */

//添加白名单
async function addAuthResult (params = null) {
  try {
    const res = React.$post(React.$api.WHITELIST_ADDWHITELIST, params)
    return res
  } catch (error) {
    console.error(error)
    return Promise.resolve(null)
  }
}

//获取白名单
async function getAuthResult (params = null) {
  try {
    const res = React.$post(React.$api.WHITELIST_GETWHITELISTS, params)
    return res
  } catch (error) {
    console.error(error)
    return Promise.resolve(null)
  }
}

//提取ip白名单url
async function getWhiteListUrl (params = null) {
  try {
    const res = React.$post(React.$api.WHITELIST_GETWHITELIST, params)
    return res
  } catch (error) {
    console.error(error)
    return Promise.resolve(null)
  }
}

//编辑白名单
async function editAuthResult (params = null) {
  try {
    console.log(params, 'params');
    const res = React.$post(React.$api.WHITELIST_LISTEDITWHITELIST, params)
    return res
  } catch (error) {
    console.error(error)
    return Promise.resolve(null)
  }
}

//删除白名单
async function delAuthResult (params = null) {
  try {
    const res = React.$post(React.$api.WHITELIST_REMOVEWHITELIST, params)
    return res
  } catch (error) {
    console.error(error)
    return Promise.resolve(null)
  }
}

//自动替换ip
async function replaceIp (params = null) {
  try {
    const res = React.$post(React.$api.USER_UPDATE_AUTOREPLACE, params)
    return res
  } catch (error) {
    console.error(error)
    return Promise.resolve(null)
  }
}

export {
  addAuthResult,
  getAuthResult,
  getWhiteListUrl,
  editAuthResult,
  delAuthResult,
  replaceIp,
}