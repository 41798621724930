/*
 * @Author: 朱占伟
 * @LastEditors: 李云涛
 * @description: 支付宝实名认证
 * @Date: 2022-03-24 14:14:42
 * @LastEditTime: 2022-04-11 11:35:22
 */


import { Form, Input, Button, Checkbox, message } from 'antd';
import { Link } from "react-router-dom"
import { NAME, IDCARD } from "src/config/regexp.config"

import { zfbAuth, getAuthResult } from 'src/model/auth';
import QRcode from "qrcode.react"

export default  function AuthRealName(props) {

  //验证信息
  const [pay, setPay] = React.useState({
    name: '',  //身份证姓名
    idCard: '',//身份证码
  })

  //是认证后返回的信息，id与二维码链接
  const [hasPay, setHasPay] = React.useState(null);


  //是否认证成功
  const [hasSuccess, setHasSuccess] = React.useState(true);


  //修改值
  const updatePay = (val, type = 'name') => {
    const obj = Object.assign(pay, {
      [type]: val
    })
    setPay(obj)
  }

  //校验传参
  const checkParams = (obj) => {
    const res = {
      isPass: true,
      msg: ''
    }
    if (!obj.name) {
      res.isPass = false
      res.msg = '请输入身份证姓名'
      return res
    }

    if (!NAME.test(obj.name)) {
      res.isPass = false
      res.msg = '请输入正确格式的身份证姓名'
      return res
    }

    if (!obj.idCard) {
      res.isPass = false
      res.msg = '请输入身份证号码'
      return res
    }

    if (!IDCARD.test(obj.idCard)) {
      res.isPass = false
      res.msg = '请输入正确格式的身份证号码'
      return res
    }

    return res
  }


  //点击支付宝认证，获取认证二维码
  const onFinish = async () => {

    //校验数据合法性
    const checkRes = checkParams(pay)
    if (!checkRes.isPass) {
      return message.error(checkRes.msg)
    }

    console.log("pay", pay)

    const res = await zfbAuth(pay)
    console.log(res);

    if(res && res.code === 200){
      //api获取 二维码链接存入变量
      setHasPay(res.data)
    }else{
      message.error(res.message)
    }
  };


  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };


  //重新认证
  const resetPay = () => {
    setHasPay(null)
    setPay({
      name: '',
      idCard: '',
    })
    setHasSuccess(true)
  }

  //获取认证结果
  const getAuthRes = async() => {
    //查询接口获取结果
    const res = await getAuthResult({transferId: hasPay.certifyId})

    if(res && res.code === 200 && res.data){
      console.log('验证成功的结果',res);
      setHasSuccess(true)
      props.next()
    }else if(res && res.code === 200 && res.data === null){
      //失败弹出信息
      message.error('认证未完成，请扫码认证后再试')
    }else if(res && res.code === 200 && !res.data){
      setHasSuccess(false)
      //失败弹出信息
      message.error('认证失败')
    }else{
      message.error(res.message)
    }
  }



  //填写认证信息
  const realAuth = () => {
    return (<div className='auth-phone pb-40'>
      <p>为保障您的账户安全，请进行信息认证</p>
      <p>平台授权支付宝安全认证，平台不会泄露您的认证信息</p>
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        name="checkPhone"
        autoComplete="off"
      >
        <Input placeholder='输入身份证姓名' maxLength="30" className='mt-20' onChange={(ev) => updatePay(ev.target.value)} />

        <div className='flex mt-20'>
          <Input placeholder='输入身份证号码' maxLength="36" onChange={(ev) => updatePay(ev.target.value, 'idCard')} />
        </div>
        <Form.Item className='mt-40'>
          <Button type="primary" htmlType="submit">
            支付宝认证
          </Button>
        </Form.Item>
      </Form>
    </div>)

  }


  // 获取创建的二维码页面
  const creatQImg = (props) => {

    return (
      <div className='auth-phone pb-40'>
        <p>打开支付宝扫描下方二维码</p>
        <p>您的信息由支付宝审核，本平台无法保留您的识别信息</p>

        <div className="img-box">
          <QRcode
            id="zfbCode"
            value={hasPay.url}
            size={200} // 二维码的大小
            fgColor="#000000" // 二维码的颜色
            style={{ margin: 'auto' }}
            level='M'
          />         
        </div>

        <div className='pay-action mt-40 flex justify-between'>
          <Button onClick={resetPay}>
            重新录入身份
          </Button>
          <Button type="primary" onClick={getAuthRes}>
            查询结果
          </Button>
        </div>

      </div>
    )

  }

  //认证失败
  const failPage = () => {
    return (
      <div className='auth-phone pb-40'>
        <h3>认证失败，请重新认证</h3>
        <div className='pay-action mt-40 flex justify-center'>
          <Button onClick={resetPay}>
            重新认证
          </Button>
        </div>

      </div>
    )
  }



  return (
    <>
      {
        !hasPay ? realAuth() : (
          hasSuccess && creatQImg() || failPage()
        )

      }

    </>
  )
}


