/*
 * @Author: 李云涛
 * @LastEditors: 李云涛
 * @description: 认证接口方法
 * @Date: 2022-03-24 16:42:12
 * @LastEditTime: 2022-03-31 10:45:27
 */

//主动获取验证结果
async function getAuthResult (params = null) {
  try {
    const res = React.$get(React.$api.AUTH_GETAUTHRESULT, params)
    return res
  } catch (error) {
    console.error(error)
    return Promise.resolve(null)
  }
}

//客户认证短信验证码
async function getAuthCode (params = null) {
  try {
    const res = React.$get(React.$api.AUTH_GETAUTHCODE, params)
    return res
  } catch (error) {
    console.error(error)
    return Promise.resolve(null)
  }
}

//客户换手机号短信验证码
async function getUpdatePhoneCode (params = null) {
  try {
    const res = React.$post(React.$api.AUTH_GETUPDATEPHONECODE, params)
    return res
  } catch (error) {
    console.error(error)
    return Promise.resolve(null)
  }
}

//验证客户认证短信验证码
async function checkAuthCode (params = null) {
  try {
    const res = React.$post(React.$api.AUTH_CHECKAUTHCODE, params)
    return res
  } catch (error) {
    console.error(error)
    return Promise.resolve(null)
  }
}

//支付宝实名认证
async function zfbAuth (params = null) {
  try {
    const res = React.$post(React.$api.AUTH_ZFBAUTH, params)
    return res
  } catch (error) {
    console.error(error)
    return Promise.resolve(null)
  }
}

export {
  getAuthResult,
  getAuthCode,
  getUpdatePhoneCode,
  checkAuthCode,
  zfbAuth,
}