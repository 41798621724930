/*
 * @Author: 李云涛
 * @LastEditors: 李云涛
 * @description: 容器/ 登录业务
 * @Date: 2022-03-23 13:39:11
 * @LastEditTime: 2022-03-31 13:48:46
 */

import App from 'views/app'
import { useNavigate } from "react-router-dom"
import { useEffect } from 'react'
import Gpage from "src/routes/global"

import { useSelector } from 'react-redux'

function LayoutContainer() {
  
  const userInfo = useSelector(state => state.userInfo)

  let navigate = useNavigate();
  useEffect(() => {
    if (userInfo) {
      console.log("是否登录", userInfo)
      if (!userInfo.identityAuth || !userInfo.phoneNumAuth) {
        return navigate("/auth");
      }
      return navigate("/");
    }
    navigate("/login");
  }, [])

  return (
    <>
      {
        userInfo && userInfo.identityAuth && userInfo.phoneNumAuth && <App /> || <Gpage />
      }
    </>
  )
}

export default LayoutContainer