/*
 * @Author: 朱占伟
 * @LastEditors: 李云涛
 * @description: 认证结果
 * @Date: 2022-03-24 15:18:39
 * @LastEditTime: 2022-04-02 10:10:23
 */
import { connect } from 'react-redux'
import { setAuth } from "src/store/action/user"
import { Result, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { setIdentityAuth } from 'src/store/action/user';

function AuthRes(props) {
  //hook dispatch
  const dispatch = useDispatch()

  //dispatch方法
  //设置身份认证成功，存入redux’
  const setIndetityVal = (e) => {
    dispatch(setIdentityAuth(e))
  }

  const [time, setTime] = React.useState(3)


  const navigate = useNavigate();



  let timer = null
  React.useEffect(() => {
    if (time > 0) {
      clearTimeout(timer)
      timer = setTimeout(() => setTime(time - 1), 1000)
    } else {
      //跳转首页
      if (time === 0) {
        console.log("跳转---------------", time)
        goHome()
      }
    }
  }, [time])


  const goHome = ()=>{
    setIndetityVal(true)
    setTimeout(()=>{navigate('/')},0)
  }

  return (
    <Result
      status="success"
      title="恭喜您，实名认证成功!"
      extra={[
        <Button key="console" className='mt-20' onClick={goHome}>
          跳转至首页 <span className='pl-10 font-main'>({time}S)</span>
        </Button>,
      ]}
    />
  )
}


export default (AuthRes)
