/*
 * @Author: 朱占伟
 * @LastEditors: 李云涛
 * @description: page description
 * @Date: 2021-09-10 10:59:45
 * @LastEditTime: 2022-04-11 12:46:31
 */

import "./index.less"

import { Form, Input, Button, Table, Modal, Select, ConfigProvider, message } from "antd"
import zhCN from "antd/es/locale/zh_CN"
import React, { useEffect, useState, useRef, useCallback } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { getPackageData } from "src/model/packageManager"

//导入生成列表列函数   --   套餐状态
import { generateColumns, packageStatus } from "./data"

function PackageManage() {

  //redux数据
  const userInfo = useSelector(state=>state.userInfo)

  const navigate = useNavigate()

  const [data, setData] = useState([])

  const [formData, setFormData] = useState({
    sequenceList: null,
    name: null,
    page: 1,
    size: 10,
    proxyState: 0,
    onreset: 0,
  })

  function changeForm(type, val) {
    let e = {}
    e = Object.assign(formData, {
      [type]: val
    })
    setFormData({
      ...e
    })
    e = null
  }

  function resetForm() {
    let params = {
      sequenceList: null,
      name: null,
      page: 1,
      size: 10,
      proxyState: 0,
    }
    setFormData({
      ...params,
      onreset: formData.onreset + 1
    })
  };

  useEffect(()=>{
    if(formData.onreset === 0){
      return 
    }
    init()
  }, [formData.onreset])


  //提取ip函数
  function extractIp(state, e) {
    if(state === 2){
      message.error('该套餐已禁用')
      return
    }
    if(state === 3){
      message.error('该套餐已用完')
      return
    }
    if(state === 4){
      message.error('该套餐已到期')
      return
    }
    navigate('/extractIp', {
      state: {
        package: e
      }
    })
  }

  //请求数据
  async function init(val = {
    ...formData,
    brand: userInfo.brand,
    userId: userInfo.id,
    sequenceList: formData.sequenceList ? formData.sequenceList.split(/,|，/) : null,
    proxyState: formData.proxyState !== 0 ? formData.proxyState : null
  }) {
    const res = await getPackageData(val)
    if(res && res.code === 200){
      setData(res.data)
    }else{
      message.error(res.message)
    }
  }

  
  const changePage = (page, size) => {
    setFormData({
      ...formData,
      page,
      size,
    })
  }

  //表格分页配置
  const paginationProps = {
    showQuickJumper: true,
    showSizeChanger: true,
    current: formData.page,
    pageSize: formData.size,
    showTotal: ()=>`共${data.total}条`,
    total: data.total,
    onChange: changePage,
  }

  //搜索search
  function onSearch() {
    if(formData.page !== 1){
      setFormData({
        ...formData,
        page: 1,
      })
    }else{
      init()
    }
  }

  useEffect(()=>{
    setTimeout(()=>{
      init()
    }, 0)
  },[formData.page, formData.size])

  return (
    <React.Fragment>
      <div className="package-form-wrap">
        <Form
          wrapperCol={{ span: 24 }}
          className="flex package-top-form"
        >
          <Form.Item label="套餐编号" className="mr-30 mb-5">
            <Input type="text" value={formData.sequenceList} placeholder="请输入套餐编号" style={{width: 180}} onChange={(e)=>{changeForm('sequenceList', e.target.value)}} />
          </Form.Item>
          <Form.Item label="套餐名称" className="mr-30 mb-5">
            <Input type="text" value={formData.name} placeholder="请输入套餐名称" style={{width: 180}} onChange={(e)=>{changeForm('name', e.target.value)}} />
          </Form.Item>
          <Form.Item label="套餐状态" className="mr-30 mb-5">
            <Select
              placeholder="请选择套餐状态"
              style={{width:180}}
              defaultValue={formData.proxyState}
              value={formData.proxyState}
              onChange={(e)=>{changeForm('proxyState', e)}} 
            >
              {
                packageStatus.map((item)=>{
                  return (
                    <Select.Option key={item.id} value={item.id}>
                      {item.label}
                    </Select.Option>
                  )
                })
              }
            </Select>
          </Form.Item>
          <Form.Item>
            <Button className="ml-15" onClick={onSearch}>搜索</Button>
            <Button className="ml-15" onClick={resetForm}>重置</Button>
          </Form.Item>
        </Form>
      </div>
      <div className="package-table-wrap">
        <ConfigProvider locale={zhCN}>
          <Table
            columns={generateColumns({ extractIp })}  //使用函数生成表格列
            dataSource={data.list}  //数据源
            pagination={paginationProps}
          />
        </ConfigProvider>
      </div>
    </React.Fragment>
  )
}

export default PackageManage