/*
 * @Author: 朱占伟
 * @LastEditors: 李云涛
 * @description: 项目入口文件
 * @Date: 2021-09-09 10:40:15
 * @LastEditTime: 2022-04-11 16:11:47
 */
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import {render} from 'react-dom';

import 'assets/css/public.less';
import 'assets/css/icon.css';
import 'assets/css/ant.reset.less';

import LayoutContainer from './components/LayoutPage';
import store, { persistor } from "src/store"

import { post , get} from 'utils/request';

//配置文件
import api from 'config/api.config'
import regexp from 'config/regexp.config'

//挂载全局方法
React.$post = post

React.$get = get
//api配置文件
React.$api = api

//正则配置文件
React.$regexp = regexp

render(
  <ReactRouter.BrowserRouter>
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <LayoutContainer />
        </PersistGate>
      </Provider>
    </React.StrictMode>
  </ReactRouter.BrowserRouter>,
  document.getElementById('root')
);