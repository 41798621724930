/*
 * @Author: 朱占伟
 * @LastEditors: 李云涛
 * @description: api地址配置文件
 * @Date: 2021-10-18 17:50:41
 * @LastEditTime: 2022-04-08 15:42:48
 */


export default {
  //登录Api
  AUTH_LOGIN:'/auth/login',   //登录
  AUTH_LOGINOUT: '/auth/loginOut',    //登出

  //认证Api
  AUTH_GETAUTHCODE: '/auth/getAuthCode',    //客户认证短信验证码
  AUTH_GETUPDATEPHONECODE: '/auth/getAuthCode4UpdatePhone',    //客户认证短信验证码
  AUTH_CHECKAUTHCODE: '/auth/checkAuthCode',    //验证客户认证短信验证码
  AUTH_GETAUTHRESULT: '/auth/getAuthResult',    //主动获取验证结果
  AUTH_ZFBAUTH: '/auth/zfbAuth',    //支付宝实名认证

  //套餐Api
  MEAL_PROXY_LIST: '/meal/proxy/list', //套餐Api

  //白名单Api
  WHITELIST_ADDWHITELIST: '/whiteList/addWhiteList', //添加白名单
  WHITELIST_GETWHITELISTS:'/whiteList/getWhiteLists', //获取白名单
  WHITELIST_LISTEDITWHITELIST:'/whiteList/editWhiteList', //编辑白名单
  WHITELIST_REMOVEWHITELIST:'/whiteList/removeWhiteLists', //删除白名单
  USER_UPDATE_AUTOREPLACE:'/user/update/autoReplace', //修改ip自动替换
  WHITELIST_GETWHITELIST: '/whiteList/getWhiteList',  //提取ip白名单url

  //用户操作Api
  USER_UPDATE_PASSWORD: '/user/update/password',  //修改密码
  USER_UPDATE_PHONE: '/user/update/phone',  //修改手机号码
  USER_UPDATE_AUTOREPLACE: '/user/update/autoReplace',  //修改自动替换状态

  //提取IP API
  LINE_API_CITY: '/line/api/city',  //获取省份
  MEAL_PROXY_MENU: '/meal/proxy/menu'   //获取下拉菜单
}