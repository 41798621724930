/*
 * @Author: 李云涛
 * @LastEditors: 李云涛
 * @description: page description
 * @Date: 2022-03-24 09:45:36
 * @LastEditTime: 2022-04-08 16:33:41
 */

import { render } from "less"
import React from "react"

//提取ip下方说明数据配置，函数返回

//jss cssinjs
const title = {
  fontWeight:700, color:'#3E6AE2'
}

function extractIpIllustrate(e, res = {}) {
  if(e === 2){
    return {
      illustrate: [{
        attributes: 'URL',
        content: (<div>
                    http://api.tianqiip.com/getip
                  </div>),
      },{
        attributes: '方法',
        content: (<div style={title}>
                    GET
                  </div>),
      },{
        attributes: '编码方式',
        content: (<div style={title}>
                    utf-8
                  </div>),
      },{
        attributes: '参数说明',
        content: (<div>
                    <span style={title}>secret</span> ： 提取秘钥 <br/>
                    <span style={title}>num</span> (可选)：提取IP数量，不能超过200，默认值10<br/>
                    <span style={title}>yys</span> (可选)：提取IP运营商，电信、移动、联通；<br/>
                    <span style={title}>type</span> （可选）：返回类型，txt，json；<br/>
                    <span style={title}>lb</span> （可选）：txt格式返回的分隔符<br/>
                    <span style={title}>region</span> （可选）：区域编号，多个用英文逗号分隔<br/>
                    <span style={title}>port</span> ：IP协议 1:HTTP 2:HTTPS 3:SOCKS5<br/>
                    <span style={title}>time</span>（按次提取必选）：IP使用时长只支持（3,5,10,15）<br/>
                    <span style={title}>ts</span> （可选）：是否显示IP过期时间， 1：显示 默认不显示<br/>
                    <span style={title}>ys</span> （可选）：是否显示IP运营商，1：显示 默认不显示<br/>
                    <span style={title}>cs</span> （可选）：是否显示位置，1：显示 默认不显示<br/>
                  </div>),
      },{
        attributes: '异常',
        content: (<div>
                    <span style={title}>code</span> ：错误的编号；<br/>
                    <span style={title}>msg</span> ：描述信息；
                  </div>),
      },{
        attributes: '成功',
        content: (<div style={{whiteSpace: 'pre-wrap'}}>
                    返回内容的标签或字段类型可在产品管理里通过设置提取格式来定制；<br/>
                    <br/>
                    类型为txt文本的定制标签如下：<br/>
                    ip：port自定义分隔符；<br/>
                    类型为json格式的响应字段如下：<br/>
                    data：[ <br/>
                      {
                        `    {\n        ip：IP\n        port：端口\n        expire：有效时间\n        city：城市\n        isp：运营商\n   }`
                      }
                    <br/>]
                  </div>),
      },{
        attributes: '状态码',
        content: (
          <div>
            1000：获取成功； <br/>
            1001：请求格式不正确；<br/>
            1002：单次请求数量超出最大值；<br/>
            1003：请求KEY异常；<br/>
            1004：套餐已过期；<br/>
            1005：套餐提取数量上限；<br/>
            1006：暂无可用IP；<br/>
            1007：提取地区超出服务范围<br/>
          </div>
        ),
      },],
      textExample: [{
        format: (<div>
                    114.101.252.126:57577<br/>
                    114.101.252.126:57577<br/>
                    114.101.252.126:57577<br/>
                  </div>),
      }],
      jsonExample: [{
        format: (<div style={{whiteSpace:"pre-wrap"}}>
                    {
                      `{  "code":200,\n  "data":[\n     {\n     "ip":"121.227.60.236",\n      "port":"20252",\n      "prov":"辽宁",\n     "city":"鞍山",\n     }\n     {\n     "ip":"121.227.60.236",\n     "port":"20252",\n     "prov":"辽宁",\n     "city":"鞍山",\n   }\n]  }`  
                    }
                  </div>)
      }],
      whitelistIllustrate: [{
        attributes: '添加白名单接口',
        content: (
          <div>
            {res.whiteAdd}
          </div>
        )
      },{
        attributes: '删除白名单接口',
        content: (
          <div>
            {res.whiteDelete}
          </div>
        )
      },{
        attributes: '查询白名单接口',
        content: (
          <div>
            {res.whiteFetch}
          </div>
        )
      },{
        attributes: '方法',
        content: (
          <div style={title}>
            get
          </div>
        )
      },{
        attributes: '编码方式',
        content: (
          <div style={title}>
            utf-8
          </div>
        )
      },{
        attributes: '参数说明',
        content: (
          <div>
            <span style={title}>key：</span>用户账号 <br />
            <span style={title}>sign：</span>密钥签名 <br />
            <span style={title}>ip：</span>需要添加或删除的ip地址, 多个使用','分割 <br />
            <span style={title}>brand：</span>固定参数, 固定取值 2
          </div>
        )
      },{
        attributes: '异常',
        content: (
          <div style={{whiteSpace: "pre-wrap"}}>
            {`{\n   code: 错误代码}\n\n    msg: 描述信息`}
          </div>
        )
      },{
        attributes: '成功',
        content: (
          <div style={{whiteSpace: "pre-wrap"}}>
            {`{\n   code: 200\n\n    info: 描述信息\n\n    data: [xxx ,xxx] // 仅查询接口返回此字段\n}`}
          </div>
        )
      },{
        attributes: '状态码',
        content: (
          <div>
            200：操作成功；<br />
            1001：参数有误；<br />
            1002：ip地址格式无效；<br />
            1003：key无效；<br />
            1004：参数sign错误；<br />
            1005：不是代理用户；<br />
            1006：添加的新ip数量超过最大数量限制；<br />
            1007：ip已存在<br />
            1008：ip地址不在服务范围<br />
            1009：白名单中不存在指定的ip可以删除<br />
            1101：服务器内部错误<br />
          </div>
        )
      }],
      universalParams: [{
        params: '407',
        illustrate: '白名单IP校验失败'
      },{
        params: '431',
        illustrate: '代理认证口令为空'
      },{
        params: '432',
        illustrate: '账号密码错误'
      }],
      connectParams: [{
        params: '430',
        illustrate: '客户端IP非国内'
      },{
        params: '434',
        illustrate: '代理节点异常'
      },{
        params: '435',
        illustrate: '代理IP状态异常'
      },{
        params: '436',
        illustrate: '代理IP未经授权或已超出使用时长'
      }],
      forwardParams: [{
        params: '451',
        illustrate: 'IP未提取或已过期'
      },{
        params: '452',
        illustrate: '套餐信息异常'
      },{
        params: '453',
        illustrate: 'IP提取记录不存在'
      },{
        params: '454',
        illustrate: '套餐不存在'
      },{
        params: '455',
        illustrate: '用户信息异常'
      },{
        params: '456',
        illustrate: '未实名'
      },]
    }
  }else if(e === 1){
    return {
      illustrate: [{
        attributes: 'URL',
        content: (<div>
                    http://api.shenlongip.com/ip
                  </div>),
      },{
        attributes: '方法',
        content: (<div style={title}>
                    GET
                  </div>),
      },{
        attributes: '编码方式',
        content: (<div style={title}>
                    utf-8
                  </div>),
      },{
        attributes: '参数说明',
        content: (<div>
                    <span style={title}>key</span> ： 产品的key <br/>
                    <span style={title}>count</span> (可选)：提取数量, 不能超过200，默认值10；<br/>
                    <span style={title}>isp</span> (可选)：提取IP运营商，电信、移动、联通；<br/>
                    <span style={title}>pattern</span> （可选）：返回类型，txt，json；<br/>
                    <span style={title}>split</span> （可选）：txt格式返回的分隔符<br/>
                    <span style={title}>area</span> （可选）：区域编号，多个用英文逗号分隔<br/>
                  </div>),
      },{
        attributes: '异常',
        content: (<div>
                    <span style={title}>code</span> ：错误的编号；<br/>
                    <span style={title}>msg</span> ：描述信息；
                  </div>),
      },{
        attributes: '成功',
        content: (<div style={{whiteSpace: 'pre-wrap'}}>
                    返回内容的标签或字段类型可在产品管理里通过设置提取格式来定制；<br/>
                    <br/>
                    类型为txt文本的定制标签如下：<br/>
                    ip：port自定义分隔符；<br/>
                    类型为json格式的响应字段如下：<br/>
                    data：[ <br/>
                      {
                        `    {        ip：IP\n        port：端口\n        expire：有效时间\n        city：城市\n        isp：运营商\n   }`
                      }
                    <br/>]
                  </div>),
      },{
        attributes: '状态码',
        content: (
          <div>
            200：获取成功； <br/>
            201：请求格式不正确；<br/>
            202：单次请求数量超出最大值；<br/>
            203：请求KEY异常；<br/>
            204：套餐已过期；<br/>
            205：套餐提取数量上限；<br/>
            206：暂无可用IP；<br/>
            207：提取地区超出服务范围<br/>
            208：提取频率太快，请稍后重试<br/>
          </div>
        ),
      },],
      textExample: [{
        format: (<div>
                    114.101.252.126:57577<br/>
                    114.101.252.126:57577<br/>
                    114.101.252.126:57577<br/>
                  </div>),
      }],
      jsonExample: [{
        format: (<div style={{whiteSpace:"pre-wrap"}}>
                    {
                      `{  "code":200,\n  "data":[\n     {\n     "ip":"121.227.60.236",\n      "port":"20252",\n      "prov":"辽宁",\n     "city":"鞍山",\n     }\n     {\n     "ip":"121.227.60.236",\n     "port":"20252",\n     "prov":"辽宁",\n     "city":"鞍山",\n   }\n]  }`  
                    }
                  </div>)
      }],
      whitelistIllustrate: [{
        attributes: '添加白名单接口',
        content: (
          <div>
            {res.whiteAdd}
          </div>
        )
      },{
        attributes: '删除白名单接口',
        content: (
          <div>
            {res.whiteDelete}
          </div>
        )
      },{
        attributes: '查询白名单接口',
        content: (
          <div>
            {res.whiteFetch}
          </div>
        )
      },{
        attributes: '方法',
        content: (
          <div style={title}>
            GET
          </div>
        )
      },{
        attributes: '编码方式',
        content: (
          <div style={title}>
            utf-8
          </div>
        )
      },{
        attributes: '参数说明',
        content: (
          <div>
            <span style={title}>key：</span>用户账号 <br />
            <span style={title}>sign：</span>密钥签名 <br />
            <span style={title}>ip：</span>需要添加或删除的ip地址, 多个使用','分割 <br />
          </div>
        )
      },{
        attributes: '异常',
        content: (
          <div style={{whiteSpace: "pre-wrap"}}>
            {`{\n   code: 错误代码}\n\n    msg: 描述信息`}
          </div>
        )
      },{
        attributes: '成功',
        content: (
          <div style={{whiteSpace: "pre-wrap"}}>
            {`{\n   code: 200\n\n    info: 描述信息\n\n    data: [xxx ,xxx] // 仅查询接口返回此字段\n}`}
          </div>
        )
      },{
        attributes: '状态码',
        content: (
          <div>
            200：操作成功；<br />
            1001：参数有误；<br />
            1002：ip地址格式无效；<br />
            1003：key无效；<br />
            1004：参数sign错误；<br />
            1005：不是代理用户；<br />
            1006：添加的新ip数量超过最大数量限制；<br />
            1007：ip已存在<br />
            1008：ip地址不在服务范围<br />
            1009：白名单中不存在指定的ip可以删除<br />
            1101：服务器内部错误<br />
          </div>
        )
      }],
      universalParams: [{
        params: '407',
        illustrate: '白名单IP校验失败'
      },{
        params: '431',
        illustrate: '代理认证口令为空'
      },{
        params: '432',
        illustrate: '账号密码错误'
      }],

      connectParams: [{
        params: '430',
        illustrate: '客户端IP非国内'
      },{
        params: '434',
        illustrate: '代理节点异常'
      },{
        params: '435',
        illustrate: '代理IP状态异常'
      },{
        params: '436',
        illustrate: '代理IP未经授权或已超出使用时长'
      }],
      
      forwardParams: [{
        params: '451',
        illustrate: 'IP未提取或已过期'
      },{
        params: '452',
        illustrate: '套餐信息异常'
      },{
        params: '453',
        illustrate: 'IP提取记录不存在'
      },{
        params: '454',
        illustrate: '套餐不存在'
      },{
        params: '455',
        illustrate: '用户信息异常'
      },{
        params: '456',
        illustrate: '未实名'
      },]
    }
  }
}

//单选组配置
const dataFormat = [     //数据格式
  {
    label: 'TXT',
    value: 'txt',
  },
  {
    label: 'JSON',
    value: 'json'
  }
]
const delimiter = [     //分隔符
  {
    label: '回车换行(\\r\\n)',
    value: 1,
  },
  {
    label: '回车(\\r)',
    value: '\\Cr'
  },
  {
    label: '换行(\\n)',
    value: '\\Cn'
  },
  {
    label: 'Tab(\\t)',
    value: '\\Ct'
  }
]
const selectProps = [     //选择属性
  {
    label: '城市',
    value: 1,
  },
  {
    label: '运营商',
    value: 10
  },
  {
    label: '过期时间',
    value: 100
  }
]
const areaSelect = [     //地区选择
  {
    label: '全国混播',
    value: 1,
  },
  {
    label: '省分混播',
    value: 2
  }
]
const operator = [     //运营商
  {
    label: '不限',
    value: 1,
  },
  {
    label: '电信',
    value: '电信'
  },
  {
    label: '联通',
    value: '联通'
  }
]
const ipProtocol = [     //IP协议
  {
    label: 'HTTP',
    value: 0,
  },
  {
    label: 'HTTPS',
    value: 1
  },
  {
    label: 'SOCKS5',
    value: 2
  }
]

//下方表格配置

//说明系列配置
const illustrateConfig = [
  {
    align: 'center',
    width: 400,
    title: '属性',
    key: 'attributes',
    dataIndex: 'attributes',
  },
  {
    title: '内容',
    key: 'content',
    dataIndex: 'content',
    render: (content) => (
      <React.Fragment>
        {content}
      </React.Fragment>
    )
  },
]

//TEXT示例配置
const textExampleConfig = [
  {
    title: 'TEXT格式',
    key: 'format',
    dataIndex: 'format',
    render: (format) => (
      <React.Fragment>
        {format}
      </React.Fragment>
    )
  }
]
//JSON示例配置
const jsonExampleConfig = [
  {
    title: 'JSON格式',
    key: 'format',
    dataIndex: 'format'
  }
]

//参数系列设置
const paramsConfig = [
  {
    align: 'center',
    width: 400,
    title: '参数',
    key: 'params',
    dataIndex: 'params'
  },
  {
    title: '说明',
    key: 'illustrate',
    dataIndex: 'illustrate'
  },
]

//导出选项组配置
export {
  dataFormat,
  delimiter,
  selectProps,
  areaSelect,
  operator,
  ipProtocol,
}

//导出表格配置
export {
  illustrateConfig,
  textExampleConfig,
  jsonExampleConfig,
  paramsConfig,
}
//api文档
export {
  extractIpIllustrate,
}
