/*
 * @Author: 朱占伟
 * @LastEditors: 李云涛
 * @description: 登录页
 * @Date: 2022-03-23 13:41:45
 * @LastEditTime: 2022-04-08 14:23:49
 */

import "./index.less"
import { UserOutlined,UnlockFilled } from "@ant-design/icons"
import { Form, Input, Button, message } from 'antd';
import {useState} from "react"
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { setLogin } from "src/store/action/user"

import { userLogin } from "model/login"

export default function Login() {
  //获取redux变量
  const userInfo = useSelector(state => state.userInfo)

  //displch方法
  const dispatch = useDispatch()

  function setLoginAction(params) {
    dispatch(setLogin(params))
  }

  //路由
  const navigate = useNavigate()

  //用户名
  const [username ,setUserName] = useState(null)

  //密码
  const [pwd,setPwd] = useState(null);

  //登录传参
  const changeParams = (e,type = 'user')=>{
    if(type === 'user'){
      setUserName(e.target.value)
    }else{
      setPwd(e.target.value)
    }
  }

  //校验账户密码
  const checkParams = (obj) => {
    const res = {
      isPass: true,
      msg: ''
    }

    if (!obj.username) {
      res.isPass = false
      res.msg = '请输入用户名'
      return res
    }

    if (!obj.pwd) {
      res.isPass = false
      res.msg = '请输入密码'
      return res
    }

    return res

  }

  //点击登录
  const onFinish = async (values) => {
    const params = {
      username, pwd
    }

    //1. 校验数据合法性
    const checkRes = checkParams(params);
    if (!checkRes.isPass) {
      return message.error(checkRes.msg)
    }

    //2. api接口
    console.log(params);
    let res = await userLogin(params)
    console.log(res);

    if(res && res.code === 200){

      //3. 存入redux
      setLoginAction(res.data)

      //4.判断是否认证 未认证跳转认证页
      if(!res.data.identityAuth || !res.data.phoneNumAuth){
        navigate("/auth")
      }

    }else{

      message.error(res.message)

    }



    console.log('data:', params);
  };

  return (
    <div className="login">
      <div className="login-box">
        <h1 className="text-center mt-50 mb-50">客户管理后台</h1>
        <div className="login-form">
          <Form
           className="text-center"
            name="basic"
            onFinish={onFinish}
            autoComplete="on"
          >

              {/* 用户名 */}
              <Input type="text" autoComplete="off" className="login-text login-input" prefix={<UserOutlined />} placeholder="请输入登录账号" onChange={(e)=>changeParams(e,'user')} />


              {/* 密码 */}
              <Input.Password autoComplete="off" className="mt-30 login-text login-input" prefix={<UnlockFilled />} placeholder="请输入登录密码"  onChange={(e)=>changeParams(e,'pwd')} />

              <div>
              <Button type="primary" htmlType="submit" className="mt-40 login-text" style={{backgroudColor:"rgba(78, 154, 251, 1)"}}>
                登录
              </Button>
              </div>
              <p className="mt-20 text-center" style={{color:'white'}}>
              忘记密码请联系专属销售
              </p>

          </Form>
        </div>
      </div>

    </div>
  )
}
