/*
 * @Author: 朱占伟
 * @LastEditors: 李云涛
 * @description: page description
 * @Date: 2021-10-18 14:44:47
 * @LastEditTime: 2022-03-30 15:00:16
 */
import {createStore,applyMiddleware} from 'redux';
import thunk from 'redux-thunk'
import reducer from 'src/store/reducer';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'manager',
  storage,
  //黑名单
  blacklist: ["page404"]
}

const persistedReducer = persistReducer(persistConfig, reducer)

let store=createStore(persistedReducer,applyMiddleware(thunk))

let persistor = persistStore(store)

export default store
export {persistor}