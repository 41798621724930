/*
 * @Author: 李云涛
 * @LastEditors: 李云涛
 * @description: page description
 * @Date: 2022-04-01 15:50:20
 * @LastEditTime: 2022-04-01 15:57:12
 */

//获取省份
async function getCity (params = null) {
  try {
    const res = await React.$post(React.$api.LINE_API_CITY, params)
    return res
  } catch (error) {
    console.error('test:', error)
    return Promise.resolve(null)
  }
} 

//获取下拉菜单
async function getPackage (params = null) {
  try {
    const res = await React.$post(React.$api.MEAL_PROXY_MENU, params)
    return res
  } catch (error) {
    console.error('test:', error)
    return Promise.resolve(null)
  }
} 

export {
  getCity,
  getPackage
}