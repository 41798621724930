/*
 * @Author: 朱占伟
 * @LastEditors: 李云涛
 * @description: 路由实体部分
 * @Date: 2021-10-18 16:08:48
 * @LastEditTime: 2022-04-06 17:03:48
 */

import { useRoutes, Navigate } from "react-router-dom";

import PackageManage from 'views/packageManage/index'
import ExtractIp from "views/extractIp/index";
import IpWhiteList from "views/ipWhiteList/index";

export default function Routes() {

  let element = useRoutes([
    {
      path: "/",
      element: <PackageManage />,
    },
    { path: "/extractIp", element: <ExtractIp /> },
    { path: "/ipWhitelist", element: <IpWhiteList /> },
    { path: "*", element: <Navigate to="/" /> },
  ]);

  return element;
  
}

// export default class Routes extends React.Component {
//   constructor(props) {
//     super(props)
//   }
//   componentDidMount(){
//     console.log(routerConfig);
//   }
//   render() {
//     return (
//       <ReactRouter.Routes>
//         {
//           routerConfig.map((item) => {
//             return (
//               <ReactRouter.Route exact={item.exact} path={item.path} element={item.component} key={item.name} redirect={item.redirect} />
//             )
//           })
//         }
//         {/* <ReactRouter.Redirect from='/package' to="/" /> */}
//       </ReactRouter.Routes>
//     );
//   }
// }